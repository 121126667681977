(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('MaterialController', MaterialController);

    MaterialController.$inject = ['Material'];

    function MaterialController(Material) {

        var vm = this;

        vm.materials = [];

        loadAll();

        function loadAll() {
            Material.query(function(result) {
                vm.materials = result;
                vm.searchQuery = null;
            });
        }
    }
})();
