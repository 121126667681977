(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('GimnasioController', GimnasioController);

    GimnasioController.$inject = ['Gimnasio'];

    function GimnasioController(Gimnasio) {

        var vm = this;

        vm.gimnasios = [];

        loadAll();

        function loadAll() {
            Gimnasio.query(function(result) {
                vm.gimnasios = result;
                vm.searchQuery = null;
            });
        }
    }
})();
