(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('EjercicioDetailController', EjercicioDetailController);

    EjercicioDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Ejercicio', 'Media', 'Material'];

    function EjercicioDetailController($scope, $rootScope, $stateParams, previousState, entity, Ejercicio, Media, Material) {
        var vm = this;

        vm.ejercicio = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('functional7App:ejercicioUpdate', function(event, result) {
            vm.ejercicio = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
