(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('GimnasioDialogController', GimnasioDialogController);

    GimnasioDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Gimnasio', 'User', 'Cliente', 'Material'];

    function GimnasioDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Gimnasio, User, Cliente, Material) {
        var vm = this;

        vm.gimnasio = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.clientes = Cliente.query();
        vm.materials = Material.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.gimnasio.id !== null) {
                Gimnasio.update(vm.gimnasio, onSaveSuccess, onSaveError);
            } else {
                Gimnasio.save(vm.gimnasio, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('functional7App:gimnasioUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.fechaAlta = false;
        vm.datePickerOpenStatus.lastUpdate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
