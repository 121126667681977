(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('LicenciaDeleteController',LicenciaDeleteController);

    LicenciaDeleteController.$inject = ['$uibModalInstance', 'entity', 'Licencia'];

    function LicenciaDeleteController($uibModalInstance, entity, Licencia) {
        var vm = this;

        vm.licencia = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Licencia.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
