(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('EjercicioDeleteController',EjercicioDeleteController);

    EjercicioDeleteController.$inject = ['$uibModalInstance', 'entity', 'Ejercicio'];

    function EjercicioDeleteController($uibModalInstance, entity, Ejercicio) {
        var vm = this;

        vm.ejercicio = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Ejercicio.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
