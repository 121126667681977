(function () {
    'use strict';
    angular
            .module('functional7App')
            .factory('FileUpload', FileUpload);

    FileUpload.$inject = ['$http'];

    function FileUpload($http) {
        var service = {
            uploadFileToUrl: uploadFileToUrl
        };
        
        return service;
        
        function uploadFileToUrl(file, uploadUrl) {
            var fd = new FormData();
            /* 
             *notice 1st param 'file', which will be used to 
             * rettrive file from the java class.
             */
            fd.append('file', file);
            return $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            })
                    .success(function () {
                    })
                    .error(function () {
                    });
        }
    }
})();