(function () {
    'use strict';

    angular
            .module('functional7App', [
                'ngStorage',
                'tmh.dynamicLocale',
                'pascalprecht.translate',
                'ngResource',
                'ngCookies',
                'ngAria',
                'ngCacheBuster',
                'ngFileUpload',
                'ui.bootstrap',
                'ui.bootstrap.datetimepicker',
                'ui.router',
                'infinite-scroll',
                // jhipster-needle-angularjs-add-module JHipster will add new module here
                'angular-loading-bar'
            ])
            .run(run);

    angular.module('functional7App')
            .filter("trustUrl", ['$sce', function ($sce) {
                    return function (Url) {
                        return $sce.trustAsResourceUrl(Url);
                    };
                }]);

    angular
            .module('functional7App')
            .filter('mediaimage', [function () {
                    return function (Media) {
                        var array = [];
                        angular.forEach(Media, function (media) {
                            if (media.tipo === 'IAMGE')
                                array.push(media);
                        });
                        return array;
                    };
                }]);

    angular
            .module('functional7App')
            .filter('mediaaudio', [function () {
                    return function (Media) {
                        var array = [];
                        angular.forEach(Media, function (media) {
                            if (media.tipo === 'AUDIO')
                                array.push(media);
                        });
                        return array;
                    };
                }]);

    angular
            .module('functional7App')
            .filter('mediavideo', [function () {
                    return function (Media) {
                        var array = [];
                        angular.forEach(Media, function (media) {
                            if (media.tipo === 'VIDEO')
                                array.push(media);
                        });
                        return array;
                    };
                }]);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
