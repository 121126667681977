(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('MaterialDetailController', MaterialDetailController);

    MaterialDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Material', 'Media'];

    function MaterialDetailController($scope, $rootScope, $stateParams, previousState, entity, Material, Media) {
        var vm = this;

        vm.material = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('functional7App:materialUpdate', function(event, result) {
            vm.material = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
