(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('MaterialDialogController', MaterialDialogController);

    MaterialDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Material', 'Media'];

    function MaterialDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $q, entity, Material, Media) {
        var vm = this;

        vm.material = entity;
        vm.clear = clear;
        vm.eliminarMedia = eliminarMedia;
        vm.save = save;
        vm.media = Media.query({filter: 'material-is-null'});
        $q.all([vm.material.$promise, vm.media.$promise]).then(function() {
            if (!vm.material.media || !vm.material.media.id) {
                return $q.reject();
            }
            return Media.get({id : vm.material.media.id}).$promise;
        }).then(function(media) {
            vm.media.push(media);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.material.id !== null) {
                Material.update(vm.material, onSaveSuccess, onSaveError);
            } else {
                Material.save(vm.material, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('functional7App:materialUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function eliminarMedia(){
            vm.material.media = null;
        }

        var unsubscribe = $rootScope.$on('functional7App:mediaUpdate', function (event, result) {
            vm.material.media = result.data;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
