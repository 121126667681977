(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('LicenciaDialogController', LicenciaDialogController);

    LicenciaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Licencia', 'Gimnasio'];

    function LicenciaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Licencia, Gimnasio) {
        var vm = this;

        vm.licencia = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.gimnasios = Gimnasio.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.licencia.id !== null) {
                Licencia.update(vm.licencia, onSaveSuccess, onSaveError);
            } else {
                Licencia.save(vm.licencia, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('functional7App:licenciaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.fechaAlta = false;
        vm.datePickerOpenStatus.fechaCaducidad = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
