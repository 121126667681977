(function () {
    'use strict';

    angular
            .module('functional7App')
            .controller('EjercicioDialogController', EjercicioDialogController);

    EjercicioDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Ejercicio', 'Media', 'Material'];

    function EjercicioDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $q, entity, Ejercicio, Media, Material) {
        var vm = this;

        vm.ejercicio = entity;
        vm.clear = clear;
        vm.eliminarMedia = eliminarMedia;
        vm.save = save;
        vm.media = Media.query({filter: 'ejercicio-is-null'});
        $q.all([vm.ejercicio.$promise, vm.media.$promise]).then(function () {
            if (!vm.ejercicio.media || !vm.ejercicio.media.id) {
                return $q.reject();
            }
            return Media.get({id: vm.ejercicio.media.id}).$promise;
        }).then(function (media) {
            vm.media.push(media);
        });
        vm.materials = Material.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.ejercicio.id !== null) {
                Ejercicio.update(vm.ejercicio, onSaveSuccess, onSaveError);
            } else {
                Ejercicio.save(vm.ejercicio, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('functional7App:ejercicioUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function eliminarMedia() {
            vm.ejercicio.media = null;
        }

        var unsubscribe = $rootScope.$on('functional7App:mediaUpdate', function (event, result) {
            vm.ejercicio.media = result.data;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
