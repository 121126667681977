(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('LicenciaDetailController', LicenciaDetailController);

    LicenciaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Licencia', 'Gimnasio'];

    function LicenciaDetailController($scope, $rootScope, $stateParams, previousState, entity, Licencia, Gimnasio) {
        var vm = this;

        vm.licencia = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('functional7App:licenciaUpdate', function(event, result) {
            vm.licencia = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
