(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('EjercicioController', EjercicioController);

    EjercicioController.$inject = ['Ejercicio'];

    function EjercicioController(Ejercicio) {

        var vm = this;

        vm.ejercicios = [];

        loadAll();

        function loadAll() {
            Ejercicio.query(function(result) {
                vm.ejercicios = result;
                vm.searchQuery = null;
            });
        }
    }
})();
