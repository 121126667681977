(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('GimnasioDetailController', GimnasioDetailController);

    GimnasioDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Gimnasio', 'User', 'Cliente', 'Material'];

    function GimnasioDetailController($scope, $rootScope, $stateParams, previousState, entity, Gimnasio, User, Cliente, Material) {
        var vm = this;

        vm.gimnasio = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('functional7App:gimnasioUpdate', function(event, result) {
            vm.gimnasio = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
