(function() {
    'use strict';

    angular
        .module('functional7App')
        .controller('LicenciaController', LicenciaController);

    LicenciaController.$inject = ['Licencia'];

    function LicenciaController(Licencia) {

        var vm = this;

        vm.licencias = [];

        loadAll();

        function loadAll() {
            Licencia.query(function(result) {
                vm.licencias = result;
                vm.searchQuery = null;
            });
        }
    }
})();
