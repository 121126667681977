(function () {
    'use strict';

    angular
            .module('functional7App')
            .controller('MediaDialogController2', MediaDialogController);

    MediaDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Media', 'FileUpload'];

    function MediaDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, Media, FileUpload) {
        var vm = this;

        vm.media = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            // myFile referes to the file-model in the html code
            var file = $scope.myFile;
            var uploadUrl = "/api/mediafile";

            FileUpload.uploadFileToUrl(file, uploadUrl)
                    .then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('functional7App:mediaUpdate', result);
            $rootScope.$emit('functional7App:mediaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
